import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class CoScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    this.state = {
 
      u:u
    };



}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");

  axios.get("/phone/real/random")
  .then(function (response) {
    console.log(response);

  });
}










  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
          <Breadcrumb.Item href={"#/?u="+this.state.u}>
            免费选号
            </Breadcrumb.Item>
            <Breadcrumb.Item href={"#/big?u="+this.state.u}>
            特色卡
            </Breadcrumb.Item>
            <Breadcrumb.Item href={"#/cheing?u="+this.state.u}>
            好车推荐
            </Breadcrumb.Item>
          </Breadcrumb>


          <Card.Text className='hmb-co-txt'>
          <h1>合作加盟</h1>
                 <p>
                 号码宝平台，为广大卡友提供优惠套餐。面向运营商、运营商代理商、门店、卡商、校园代理、个人代理等提供全方位合作共赢方案。
                 </p>

                 <section>
                    <h2>运营商</h2>
                    <p>欢迎联通、电信、移动、广电运营商入住到号码宝，可以总部接口对接，也可以地市联通对接，可提供互联网技术对接，完成互联网下单、交付、广告投流、私域产品分发等，开拓全国大市场。</p>
                </section>

                <section>
                    <h2>运营商代理商</h2>
                    <p>欢迎联通、电信、移动、广电运营商代理入住到号码宝，可实现线上线下流量互动、靓号办理、本地特殊套餐宣传、私域传播、智能营销、选号系统、老客户拉新、直播活动、本地校园卡办理等，做大做强本地市场，具备优惠业务远程开卡营业厅，可享受全国市场红利。</p>
                </section>

                <section>
                    <h2>门店</h2>
                    <p>有实体店的老板，可与号码宝达成合作，将客流进行二次变现，也使用优惠套餐为门店吸引更多人气。</p>
                </section>


                <section>
                    <h2>卡商</h2>
                    <p>卡商可入住到号码宝，或使用号码宝丰富自身产品线。</p>
                </section>


                <section>
                    <h2>校园代理</h2>
                    <p>号码宝提供丰富产品，适合学校兼职创业，门槛低，好操作。</p>
                </section>

                <section>
                    <h2>个人代理</h2>
                    <p>号码宝提供个人副业平台，懂通信资费，为身边的人介绍办理套餐，就能将业余时间变现。</p>
                </section>


                <section>
                    <h2>联系我们</h2>
                    <p>商拓微信：13933393509</p>
                    <p>商拓电话：15733892563</p>
                    <p>上午不接电话 也是备用微信 </p>
                </section>

          </Card.Text>

        </Container>
    </div>
  }
}

export default CoScreen;
