import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {   Button , Card ,Container,Modal,Spinner ,Breadcrumb,Row,Col } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import React from 'react';
import axios from 'axios';
import CityData from './CityData';
 


export default class FlexProduct extends React.Component {
  constructor(props) {
    super(props);
    console.log(window);
    console.log("citysize" );
    console.log(CityData);
    if(window.location.hostname!=="localhost"){
      //hao.zhongdaiqi.com ->  hmb.zhongdaiqi.com
      if(window.location.hostname==="hao.zhongdaiqi.com"){
          //确保是https
          if(window.location.protocol==="http:"){
            console.log("自动跳转到https 确保用户数据安全");  
            window.location.href=window.location.href.replace("http://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }else{
            window.location.href=window.location.href.replace("https://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }
      }

      //确保是https
      if(window.location.protocol==="http:"){
        console.log("自动跳转到https 确保用户数据安全");  
        window.location.href=window.location.href.replace("http://","https://")
      }
    }
   

    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    let {vcode,newphone,provincecode,citycode}  = props.match.params;

    console.log("vcode");
    console.log(vcode);
    console.log(props.match.params.vcode);

    this.state = {
      product:{

      },
      u:u,
      imageId1PreviewUrl:'',
      imageId1File:'',
      showId1:false,
      imageId0PreviewUrl:'',
      imageId0File:'',
      showId0:false,
      imageIdxPreviewUrl:'',
      imageIdxFile:'',
      showIdx:false,
      showBtn:true,
      categories:[{ value: "1111", label: "1111" },{ value: "2222", label: "222" }],
      provinceList:CityData[86],
      cityList:[],
      areaList:[],
      province:"",
      city:"",
      area:"",
      vcode:vcode,
      newphone:newphone,
      belongProvinceCode:provincecode,
      belongCityCode:citycode,
      isSending:false

    };

    console.log("能获取到的参数情况");
    console.log(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLtSubmit = this.handleLtSubmit.bind(this);

    this.handleProvinceChange = this.handleProvinceChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleAreaChange = this.handleAreaChange.bind(this);
    this.handleSendSms = this.handleSendSms.bind(this);
    
    console.log("加载产品列表");
    console.log(props.match);

    console.log("参数");
    console.log(props);

    console.log("参数86");
    console.log(CityData[86]);

}

handleSendSms(e){

    e.preventDefault() //阻止表单提交
    console.log("发送短信");
    const {number,idname,idnum,address} = this
    //const {productPrice,productVCode,productTitle,productMode} = this.state.product;
 
   
    var that = this;
    console.log(that.state);

    let {isSending,vcode} = that.state;
    //正在发送
    if(isSending){
      return;
    }
    
    const {province,city,area} = this.state;
    var that = this;
    console.log("可以提交数据了");
   
    if(number.value==="" || !/\d{11}/gi.test(number.value)){
          alert("请输入手机号码！");
          return;
      }
    
  
      if(idname.value==="" ){
        alert("请输入姓名！");
        return;
    }
    
    if(idnum.value==="" ){
      alert("请输入证件号码！");
      return;
  }
    
  if(province==="" || city==="" || area===""){
    alert("请选择省市区");
    return;
  }
  
  if(address.value==="" ){
    alert("请输入收货地址！");
    return;
  }
  
  if(address.value.length<7 ){
    alert("详情地址不能少于7个汉字");
    return;
  }


    let orderdata = new FormData();
    orderdata.append('certNo',idnum.value);
    orderdata.append('contactNum',number.value);
    orderdata.append('vcode',vcode);
    axios.post("/hopeapi/lt/sms",orderdata)
    .then(function (response) {
         console.log(response);
    });

    //开始倒计时
    that.setState({
        isSending:true
    });
    var smsCountDown = 60;
    var smsCountDownInterval =setInterval(function(){
      smsCountDown--;
      that.setState({smsCountDown:smsCountDown});
      if(smsCountDown<1){
        clearInterval(smsCountDownInterval);
        that.setState({isSending:false});
      }
    }, 1000);


}

handleImageId1Change(e) {
  console.log("wa you want to upload");
  e.preventDefault();
    
  var reader = new FileReader();
  var file = e.target.files[0];
  
  reader.onloadend = () => {
    console.log('文件名为—',file);
    console.log('文件结果为—',reader.result);
    this.setState({
      imageId1File:file,
      imageId1PreviewUrl: reader.result,
      showId1:true
    });
  }
  
  reader.readAsDataURL(file) 

}

handleImageId0Change(e) {
  console.log("wa you want to upload");
  e.preventDefault();
    
  var reader = new FileReader();
  var file = e.target.files[0];
  
  reader.onloadend = () => {
    console.log('文件名为—',file);
    console.log('文件结果为—',reader.result);
    this.setState({
        imageId0File:file,
        imageId0PreviewUrl: reader.result,
        showId0:true
    });
  }
  
  reader.readAsDataURL(file) 

}

handleImageIdxChange(e) {
  console.log("wa you want to upload");
  e.preventDefault();
    
  var reader = new FileReader();
  var file = e.target.files[0];
  
  reader.onloadend = () => {
    console.log('文件名为—',file);
    console.log('文件结果为—',reader.result);
    this.setState({
       imageIdxFile:file,
       imageIdxPreviewUrl: reader.result,
       showIdx:true
    });
  }
  
  reader.readAsDataURL(file) 

}

uploadImage(api_url,file,orderCode,imageToken,nextJob){
  const formData = new FormData();
  console.log("开始上传照片------");
  // formData.append('file',value);
  formData.append('file', file)
  formData.append('token',imageToken);
  formData.append('orderCode',orderCode);
  let config = {
    method: 'post',
    headers:{'Content-Type': 'multipart/form-data'}
  }
  axios.post(api_url,formData,config).then((res) => {
    console.log("图片上传结果");
    console.log(res);
    if (res.status != 200) {
      alert("照片上传失败，请重试或者联系客服提交！");
      return false;
     }
    if (res.status === 200 && !res.data.status) {
 
      alert("照片上传失败，请重试或者联系客服提交！");
      return false;
    }

    //继续执行
    nextJob();

  }).catch((error) => {
    alert("照片上传失败，请重试或者联系客服提交！");
  })

}

uploadImageAll(uploadImageConfig,successJob,errorJob){
  const {api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken} = uploadImageConfig;
  const formData = new FormData();
  console.log("开始上传照片------");
  // formData.append('file',value);
  formData.append('id1', imageId1File);
  formData.append('id0', imageId0File);
  formData.append('idx', imageIdxFile);
  formData.append('token',imageToken);
  formData.append('orderCode',orderCode);
  let config = {
    method: 'post',
    headers:{'Content-Type': 'multipart/form-data'}
  }
  axios.post(api_url,formData,config).then((res) => {
    console.log("图片上传结果");
    console.log(res);
    if (res.status != 200) {
  
      errorJob();
      return false;
     }
    if (res.status === 200 && !res.data.status) {
 
    
      errorJob();
      return false;
    }

    //继续执行
    successJob();

  }).catch((error) => {
    errorJob();
 
  })

}

handleProvinceChange = (event)=>{
  const {name, value} = event.target;
   
  console.log(name);
  console.log(value);
  
  if(value==="请选择省"){
    this.setState({province:""});
    return;
  }
  this.setState({province:value,city:"",area:""});
 

  //反查省code
  let provinceItem = Object.entries( this.state.provinceList).filter(x => {
      return value === x[1] ;
  })
  if(provinceItem.length===0){
    this.setState({province:""});
    return;
  }
  console.log(provinceItem);
  console.log(CityData[provinceItem[0]]);
  this.setState({provinceCode:provinceItem[0][0]});
  this.setState({cityList:CityData[provinceItem[0][0]]});
  
}

handleCityChange = (event)=>{
  const {name, value} = event.target;
   
  console.log(name);
  console.log(value);
  if(value==="请选择市"){
    this.setState({city:"",area:""});
    return;
  }
  this.setState({ city:value,area:""});
  //反查市code
  let cityItem = Object.entries( this.state.cityList).filter(x => {
      return value === x[1] ;
  })
  if(cityItem.length===0){
    return;
  }
  console.log(cityItem);
  this.setState({cityCode:cityItem[0][0]});
  this.setState({areaList:CityData[cityItem[0][0]]});
  
}

handleAreaChange = (event)=>{
  const {name, value} = event.target;
  console.log(name);
  console.log(value);
  if(value==="请选择区"){
    //清空客户的选择
    this.setState({area:""});
    return;
  }
  this.setState({area:value});


    //反查市code
    let areaItem = Object.entries( this.state.areaList).filter(x => {
        return value === x[1] ;
    })
 
  console.log(areaItem);
  this.setState({areaCode:areaItem[0][0]});
  console.log(this.state.province);
  console.log(this.state.city);
  console.log(value);
 
}



handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  console.log(this.state);
  const {number,idname,idnum,address,smsCode} = this
  //const {productPrice,productVCode,productTitle,productMode} = this.state.product;
  let productPrice = 0;
  let productTitle= "";
  let productMode="info";

  const {province,city,area} = this.state;
  var that = this;
  console.log("可以提交数据了");
 
  if(number.value==="" || !/\d{11}/gi.test(number.value)){
        alert("请输入手机号码！");
        return;
    }
  


    if(idname.value==="" ){
      alert("请输入姓名！");
      return;
  }
  
  if(idnum.value==="" ){
    alert("请输入证件号码！");
    return;
}
  
if(province==="" || city==="" || area===""){
  alert("请选择省市区");
  return;
}

if(address.value==="" ){
  alert("请输入收货地址！");
  return;
}

if(address.value.length<7 ){
  alert("详情地址不能少于7个汉字");
  return;
}

if(smsCode.value===""  || smsCode.value.length<3){
    alert("请输入验证码");
    return;
}

 
//如果当前业务为需要传照片的，则检查照片是否上传
if(productMode==="image"){
    console.log(this.state.imageId1File);
    console.log(this.state.imageId0File);
    console.log(this.state.imageIdxFile);

    if(!this.state.imageId1File || !this.state.imageId0File || !this.state.imageIdxFile){
      alert("请上传照片！");
      return;
    }

}

    let orderdata = new FormData();
    orderdata.append('productVCode',this.state.vcode);
    orderdata.append('productTitle',"联通选号");
    orderdata.append('idName',idname.value);
    orderdata.append('idNum',idnum.value);
    orderdata.append('phone',number.value);
    orderdata.append('address',address.value);
    orderdata.append('provinceCode',this.state.provinceCode);
    orderdata.append('provinceName',this.state.province);
    orderdata.append('cityCode',this.state.cityCode);
    orderdata.append('cityName',this.state.city);
    orderdata.append('areaCode',this.state.areaCode);
    orderdata.append('areaName',this.state.area);
    orderdata.append('belongProvinceCode',this.state.belongProvinceCode);
    orderdata.append('belongCityCode',this.state.belongCityCode);
    orderdata.append('newPhone', this.state.newphone);
    orderdata.append('smsCode',smsCode.value);
    orderdata.append('channel',this.state.u);
 
  that.setState({
      showBtn:false
  });
  axios.post("/hopeapi/lt/order",orderdata)
  .then(function (response) {
    console.log(response);

    if(response.status!==200){
      alert("下单失败，稍后重试");
      that.setState({
        showBtn:true
       });
      return;
    }
  
    if(!response.data.status || response.data.status==="false"){
      alert(response.data.message);
      that.setState({
        showBtn:true
       });
      return;
    }
    number.value="";
    idname.value="";
    idnum.value="";
    number.value="";
    address.value="";
    //如产品需要提交照片，则开始上传照片
    if(productMode==='image'){
        //"/hopeapi/biz/upload/id1"
        //  api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken
        var uploadImageConfig={
          api_url: "/hopeapi/biz/upload/ids",
          imageId1File: that.state.imageId1File,
          imageId0File: that.state.imageId0File,
          imageIdxFile: that.state.imageIdxFile,
          orderCode: response.data.data.orderCode,
          imageToken: response.data.data.imageToken
        }
        that.uploadImageAll(uploadImageConfig,function(){
          if(productPrice>0){

            that.setState({
              showBtn:true,
              showId1:false,
              showId0:false,
              showIdx:false
             });
             that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
          }
        },function(){

           //alert("照片上传失败，请重试或者联系客服提交！");
          that.setState({
            showBtn:true
           });
         
           that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
        });

    }else{
     
      if(productPrice>0){
        //alert("您下单的是付费产品，请联系页面底部平台客服，支付费用。");
        //跳到付款提示页面，拍出付款码/付款按扭

        that.setState({
          showBtn:true
         });

         that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
      }else{
        alert(response.data.message);
      }
    }
   
  });
}


handleLtSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {productPrice,productVCode,productTitle,productMode} = this.state.product;
  const {u} = this.state;
  var that = this;
  that.props.history.push(`/lt/${productVCode}/${u}`);
}



  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
            <Breadcrumb.Item href={"#/cha?u="+this.state.u}>
               查单
            </Breadcrumb.Item>

            <Breadcrumb.Item active>三大运营商优惠套餐</Breadcrumb.Item>
          </Breadcrumb>

          <Card body className="mt-3"  style={{textAlign:"left"}}>
                领取号码：   {this.state.newphone}
         </Card>

          <Form style={{marginTop: '1em'}}>

          <Row>
            <Col>
            
            <Form.Group controlId="formBasicIdName" >
            <Form.Control type="text" placeholder="输入姓名" ref={c => this.idname = c}  name="idname" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
          
            </Col>
          </Row>

          <Row>
            <Col>
            <Form.Group controlId="formBasicIdNum" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="输入身份证号" ref={c => this.idnum = c}  name="idnum" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
 
          
            </Col>
          </Row>



          <Row>
            <Col>
            
            <Form.Group controlId="formBasicNumber" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="输入联系号码" ref={c => this.number = c}  name="number" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>

            </Col>
          </Row>
      <Row  style={{marginTop: '0.5em'}}>
      
        <Col>   
        
             <Form.Control
                            required
                            name="province"
                            as="select"
                            placeholder="province"
                            onChange={this.handleProvinceChange}
                        >
    
                     <option key="-1">请选择省</option>
                  {
                         Object.entries( this.state.provinceList).map(x => {
                              return <option key={x[0]}>{x[1]}</option>
                          })
                      }
                        </Form.Control></Col>
        <Col> 
        
            <Form.Control
                            required
                            name="city"
                            as="select"
                            placeholder="city"
                            onChange={this.handleCityChange}
                        >
    
                     <option key="-1">请选择市</option>
                      {
       

                          Object.entries( this.state.cityList).map(x => {
                            return <option key={x[0]}>{x[1]}</option>
                        })
                      }
                        </Form.Control></Col>
        <Col><Form.Control
                            required
                            name="area"
                            as="select"
                            placeholder="area"
                            onChange={this.handleAreaChange}
                        >
    
                     <option key="-1">请选择区</option>
                     {
                        Object.values( this.state.areaList) .map(x => {
                              return <option key={x}>{x}</option>
                          })
                      }
                        </Form.Control></Col>
      </Row>

      <Row>
            <Col>
            

            <Form.Group controlId="formBasicAddress" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="详细地址(至少7个汉字)" ref={c => this.address = c}  name="address" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>

            </Col>
            </Row>

            <Row>
    <Col>
    <InputGroup className="mb-3"  style={{marginTop: '0.5em'}} > 
        <Form.Control
          placeholder="请输入验证码"
          aria-label="请输入验证码"
          aria-describedby="basic-addon2" ref={c => this.smsCode = c}  name="smsCode"
        />
        <Button variant="outline-secondary" id="button-addon2" onClick={this.handleSendSms} >
        <div style={{display:this.state.isSending?"none":"block"}}>  发送验证码 </div>
        <div style={{display:this.state.isSending?"block":"none"}}>  {this.state.smsCountDown<60? this.state.smsCountDown:'  '}秒 </div>
        </Button>
      </InputGroup>

    </Col>
  </Row>

      
       
         <div style={{display:this.state.product.productMode==='image'?"block":"none"}}>
           <div className="hmb-product-tip">根据有关部门要求，必须上传真实身份证照片。<span style={{color: "red"}}>未上传照片，订单无效。</span></div>

          <div className="clearfix"></div>


<Container>
  <Row>
    <Col>
          <div className="hmb-van-uploader" style={{display: this.state.showId1?'none':'block'}}>
            <input type="file" accept="image/*" className="hmb-van-uploader__input"  onChange={(e)=>this.handleImageId1Change(e)} ></input>
          </div> 
          <img src={this.state.imageId1PreviewUrl}  className="hmb-preview-pic" style={{display: this.state.showId1?'block':'none'}}></img>
    </Col>
    <Col ><img src='face.png' className='hmb-product-idcard-image' /></Col>
  </Row>
  <Row>
    <Col className='hmb-uploader-tip'>证件四角边缘空隙2厘米以上，手机横拍，照片清晰不模糊，无水印，无反光，无PS</Col>
  </Row>
  <Row>
    <Col>
            <div className="hmb-van-uploader"  style={{display: this.state.showId0?'none':'block'}}>
            <input type="file" accept="image/*" className="hmb-van-uploader__input"  onChange={(e)=>this.handleImageId0Change(e)}></input>
          </div> 
          <img  src={this.state.imageId0PreviewUrl}   className="hmb-preview-pic" style={{display: this.state.showId0?'block':'none'}}></img>
    </Col>
    <Col><img src='back.png' className='hmb-product-idcard-image' /></Col>
  </Row>
  <Row>
    <Col className='hmb-uploader-tip'>证件四角边缘空隙2厘米以上，手机横拍，照片清晰不模糊，无水印，无反光，无PS</Col>
  </Row>
  <Row>
    <Col>
          <div className="hmb-van-uploader"  style={{display: this.state.showIdx?'none':'block'}}>
            <input type="file" accept="image/*" className="hmb-van-uploader__input"  onChange={(e)=>this.handleImageIdxChange(e)}></input>
          </div> 
          <img  src={this.state.imageIdxPreviewUrl}   className="hmb-preview-pic"  style={{display: this.state.showIdx?'block':'none'}}></img>
    </Col>
    <Col><img src='head.png' className='hmb-product-idcard-image' /></Col>
  </Row>
  <Row>
    <Col className='hmb-uploader-tip'>无需手持身份证，胸部以上，露出双肩，人像面部比例不低于1/3，无水印，无反光，无PS</Col>
  </Row>

 

</Container>
         </div>



         <div className="text-center">
          <Button variant="primary"  style={{ marginTop: '0.5em', display: this.state.showBtn?'block':'none' }}  type="button"  onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block">
            立即提交
          </Button>

          <Button variant="default"  style={{ marginTop: '0.5em', display: !this.state.showBtn?'block':'none' }}  type="button"    className="btn btn-primary btn-lg btn-block">
             正在提交，请稍后
          </Button>

          <Card body className="mt-3"  style={{textAlign:"center",fontSize:"12px",fontWeight: "bold",color:"red", display: this.state.product.productStatus==='候车'?'block':'none' }}>
              下单候车，延期办理,耐心等待。着急请看<a href='/#/big'>其他产品</a>
          </Card>
            
          </div>


        </Form>
 
           
        </Container>
       
    </div>
  }
}


