import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class TianShiScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    this.state = {
 
      u:u
    };



}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  console.log("可以提交数据了");

  axios.get("/phone/real/random")
  .then(function (response) {
    console.log(response);

  });
}










  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
          <Breadcrumb.Item href={"#/?u="+this.state.u}>
            免费选号
            </Breadcrumb.Item>
            <Breadcrumb.Item href={"#/big?u="+this.state.u}>
            特色卡
            </Breadcrumb.Item>
            <Breadcrumb.Item href={"#/cheing?u="+this.state.u}>
            好车推荐
            </Breadcrumb.Item>
          </Breadcrumb>

          <Card.Text className='hmb-co-txt'>
                  <h1>天使系统</h1>
                  <p>
                  号码宝天使系统是老带新邀请系统，老用户邀请新用户，可以获得邀请福利。
                  </p>
  
                  <section>
                      <h2>申请开通权限，请联系</h2>
                      <p>商拓微信：13682586395</p>
                      <p>商拓电话：15733892563</p>
                  </section>
          </Card.Text>
        </Container>
    </div>
  }
}

export default TianShiScreen;
